<template>
    <div>
        <!-- Title and Logo -->
        <HeadTitle />
        <!-- end Logo and Title -->

        <!-- Panel Start here -->
        <panel title="LIST PROVINSI" class="panel panel-success">
            <!-- <template slot="header">
                <h6 class="font-weight-bold mr-auto"> LIST PEMBERI PARTISIPASI</h6>
            </template> -->
            <div class="pl-4 py-2 border shadow-sm">
                <span class="px-2"
                    ><b-button variant="primary" @click="create" pill
                        ><i class="fa fa-plus pr-1"></i> Tambah
                        Provinsi</b-button
                    ></span
                >
                <span class="px-2"
                    ><b-button variant="primary" @click="reload" pill
                        ><i class="fa fa-redo-alt pr-2"></i> Refresh</b-button
                    ></span
                >
            </div>
            <div class="py-3">
                <b-card class="shadow-sm rounded-0">
                    <!-- filter section -->
                    <b-row>
                        <b-col md="6" class="mb-3">
                            <b-form-group
                                :label-cols="3"
                                label="Filter By"
                                class="mb-0 my-1"
                            >
                                <b-input-group size="md">
                                    <b-form-select
                                        v-model="filterBy"
                                        :options="fieldOptions"
                                        @change="changeFilterBy()"
                                    >
                                        <option slot="first" :value="'all'"
                                            >All Data</option
                                        >
                                    </b-form-select>
                                </b-input-group>
                            </b-form-group>
                            <b-form-group
                                :label-cols="3"
                                label="Filter"
                                class="mb-0"
                                description="Type to Search or Click Clear to Stop Searching "
                            >
                                <b-input-group size="md">
                                    <b-form-input
                                        v-model="filter"
                                        placeholder="Type to Search"
                                        debounce="500"
                                    />
                                    <b-input-group-append>
                                        <b-btn
                                            :disabled="!filter"
                                            @click="filter = ''"
                                            variant="info"
                                            >Clear</b-btn
                                        >
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" class="mt-1">
                            <b-form-group
                                :label-cols="3"
                                label="Sort"
                                class="mb-0 my-1"
                            >
                                <b-input-group size="md">
                                    <b-form-select
                                        v-model="sortBy"
                                        :options="fieldOptions"
                                    >
                                        <option slot="first" :value="null"
                                            >-- none --</option
                                        >
                                    </b-form-select>
                                    <b-form-select
                                        :disabled="!sortBy"
                                        v-model="sortDesc"
                                        slot="append"
                                    >
                                        <option :value="false">Asc</option>
                                        <option :value="true">Desc</option>
                                    </b-form-select>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <!-- end filter section -->
                    <!-- end Tools & Search -->

                    <!-- Data Table & Pagination -->
                    <b-table
                        show-empty
                        small
                        id="masTable"
                        ref="masTable"
                        head-variant="light"
                        :hover="true"
                        :busy.sync="isBusy"
                        :items="myGetData"
                        :fields="fields"
                        :current-page="currentPage"
                        :per-page="perPage"
                        :filter="filter"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        bordered
                        striped
                    >
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                        <template v-slot:table-colgroup="scope">
                            <col
                                v-for="field in scope.fields"
                                :key="field.key"
                                :style="{
                                    width:
                                        field.key === 'actions' ? '105px' : '',
                                }"
                            />
                        </template>
                        <template #head()="data">
                            <span style="white-space: pre;">{{
                                data.label
                            }}</span>
                        </template>

                        <!-- button actions -->
                        <template v-slot:cell(actions)="data">
                            <b-dropdown
                                split
                                class="d-flex align-item-center"
                                size="sm"
                                variant="info"
                            >
                                <template #button-content>
                                    <i class="fa fa-cogs pr-1"></i> Aksi
                                </template>
                                <b-dropdown-item @click="edit(data.item.id)"
                                    ><i class="fa fa-edit"></i>
                                    Edit</b-dropdown-item
                                >
                                <b-dropdown-item
                                    @click="
                                        swalNotification('error', data.item.id)
                                    "
                                    ><i class="fa fa-trash-alt"></i>
                                    Hapus</b-dropdown-item
                                >
                            </b-dropdown>
                        </template>
                        <!-- end button actions -->
                    </b-table>
                    <div class="divider"></div>

                    <!-- pagination section -->
                    <b-row>
                        <div class="divider"></div>
                        <b-col md="6" class="my-0">
                            <b-form-group
                                :label-cols="2"
                                label="Per page"
                                class="mb-0"
                            >
                                <b-form inline>
                                    <b-form-select
                                        :options="pageOptions"
                                        style="width: 100px;"
                                        v-model="perPage"
                                    />
                                    <label class="ml-1 mr-1">/</label>
                                    <b-form-input
                                        :disabled="true"
                                        v-model="totalRows"
                                        style="width: 100px;"
                                        class="text-right"
                                    />
                                    <label class="ml-1">Rows</label>
                                </b-form>
                            </b-form-group>
                        </b-col>
                        <b-col md="6" class="my-0">
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                                align="fill"
                                size="md"
                                class="my-0 "
                            ></b-pagination>
                        </b-col>
                    </b-row>
                    <!-- end pagination section -->

                    <!-- end Data Table & Pagination -->
                </b-card>
            </div>
        </panel>
        <!-- Panel end here -->
        <!-- Modal Tambah transaksi -->
        <b-modal
            :title="editMode ? 'Edit Data Provinsi' : 'Tambah Data Provinsi'"
            v-model="showModalForm"
            id="modal"
            hide-footer
            no-close-on-backdrop
            size="lg"
        >
            <form
                @submit.prevent="editMode ? update(prov_id) : store()"
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Kode Wilayah <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-7">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('kode'),
                                    }"
                                    v-model="form.kode"
                                    placeholder="Masukkan Kode Wilayah"
                                />
                                <em
                                    v-if="form.errors.has('kode')"
                                    class="form-text text-danger"
                                    >{{ errors.kode[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row m-3">
                    <label class="col-md-3 col-form-label font-weight-bold"
                        >Nama Provinsi <span class="text-danger">*</span></label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="text"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has('nama'),
                                    }"
                                    v-model="form.nama"
                                    placeholder="Masukkan Nama Provinsi"
                                    style="text-transform: uppercase;"
                                />
                                <em
                                    v-if="form.errors.has('nama')"
                                    class="form-text text-danger"
                                    >{{ errors.nama[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <span>
                    <b-button variant="primary" type="submit"
                        >
                        <span v-if="isLoading">
                            <b-spinner variant="primary" label="Spinning" small></b-spinner>
                        </span>
                        <i class="fa fa-save" v-else></i>
                        Simpan
                        </b-button>
                </span>
                <span class="float-right">
                    <b-button variant="outline-secondary" @click="close"
                        ><i class="fa fa-times"></i> Tutup</b-button
                    >
                </span>
            </form>
        </b-modal>
        <!-- end Modal -->
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import Logo from "@/assets/images/logoSumut.png";
import axios from "axios";
import HeadTitle from "@/components/header/HRetribusi.vue";
import { mapState } from "vuex";
import hapusToken from "@/helper/hapusLocalStore";

export default {
    mixins: [hapusToken],
    components: {
        HeadTitle,
    },
    data() {
        return {
            selected: "1",
            imageLogo: Logo,
            // Tools
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,
            roleArr: ["Maintenance", "Administrator", "Pimpinan", "Pegawai"],
            role: JSON.parse(localStorage.getItem("roles"))[0],
            /*  columns: [{
				label: 'KODE',
				field: 'kode',
				tdClass: 'text-nowrap',
				thClass: 'text-nowrap'
			}, {
				label: 'NAMA URUSAN',
				field: 'nama',
				thClass: 'text-nowrap',
				tdClass: 'text-nowrap'
			}, {
				label: 'Opsi',
				field: 'action',
				type: 'percentage',
				width: '10%',
				tdClass: 'text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap',
				thClass: 'text-center text-nowrap'
			}],
      rows: [
        { id:1, kode: '1', nama:'Urusan Wajib'},
        { id:2, kode: '1', nama:'Urusan Wajib'},
        { id:3, kode: '1', nama:'Urusan Wajib'},
        { id:4, kode: '1', nama:'Urusan Wajib'},
	  ], */
            fields: [
                {
                    key: "index",
                    label: "No.",
                },
                {
                    key: "kode",
                    label: "KODE WILAYAH",
                    sortable: true,
                },
                {
                    key: "nama",
                    label: "PROVINSI",
                    sortable: true,
                },
                {
                    key: "actions",
                    label: "opsi",
                    sortable: false,
                },
            ],
            status_id: null,
            editMode: false,
            showModalForm: false,
            form: new Form({
                id: "",
                kode: "",
                nama: "",
            }),
            errors: [],
            prov_id: "",
            isLoading: false
        };
    },
    computed: {
        fieldOptions() {
            // Create an options list from our fields
            return this.fields
                .filter((f) => f.sortable)
                .map((f) => {
                    return {
                        text: f.label,
                        value: f.key,
                    };
                });
        },
        ...mapState(["filterTest"]),
    },
    mounted() {
        this.status_id = JSON.parse(localStorage.getItem("statusRegistrasi"));
    },
    methods: {
        myGetData(ctx) {
            // ctx =
            // berasal dari tag <b-table></b-table>
            //this.$Progress.start();
            this.isBusy = true;
            let promise = axios.get(
                "/api/data-induk/wilayah-indonesia/provinsi",
                {
                    params: {
                        page: ctx.currentPage,
                        perpage: ctx.perPage,
                        sortby: ctx.sortBy,
                        sortdesc: ctx.sortDesc,
                        filter: ctx.filter,
                        filterby: this.filterBy,
                        statusRegistrasiId: this.status_id,
                    },
                }
            );
            return promise
                .then((response) => {
                    const items = response.data.data;
                    // Data Pagination
                    // configPagination(response.data.meta);
                    this.totalRows = response.data.meta.total;
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.finish();
                    this.isBusy = false;
                    return items;
                })
                .catch((error) => {
                    // Here we could override the busy state, setting isBusy to false
                    //this.$Progress.fail();
                    this.isBusy = false;
                    if (error.response.status === 401) {
                        // hapusToken Mixin
                        this.clearAll();
                    }
                    // Returning an empty array, allows table to correctly handle
                    // internal busy state in case of error
                    return [];
                });
        },
        reload() {
            // with id="masTable" in tag b-table
            // this.$root.$emit('bv::refresh::table', 'masTable')
            // with property ref="masTable" in tag b-table
            this.$refs.masTable.refresh();
            localStorage.removeItem("filterView");
        },
        changeFilterBy() {
            if (this.filter) {
                this.$refs.masTable.refresh();
            }
        },
        swalNotification(swalType, id) {
            var btnClass = swalType == "error" ? "danger" : swalType;
            btnClass = swalType == "question" ? "primary" : btnClass;
            this.$swal({
                title: "Anda yakin ?",
                text: "Anda tidak akan bisa mengembalikan data!",
                type: swalType,
                showCancelButton: true,
                buttonsStyling: false,
                confirmButtonText: "Hapus",
                cancelButtonText: "Cancel",
                confirmButtonClass: "btn m-r-5 btn-" + btnClass + "",
                cancelButtonClass: "btn btn-default",
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .delete(
                            "/api/data-induk/wilayah-indonesia/provinsi/" + id
                        )
                        .then((response) => {
                            this.$swal({
                                title: "Data Berhasil Dihapus!",
                                icon: "success",
                                type: "success",
                                showCloseButton: true,
                                showConfirmButton: false,
                                timer: 5000,
                            }).catch((error) => {
                                if (error.response.status === 401) {
                                    // hapusToken Mixin
                                    this.clearAll();
                                }
                            });
                        });
                }
                this.reload();
            });
        },
        editButton(index) {
            this.$router.push({ name: "EditNPWP", params: { id: index } });
        },
        filterStatus(number) {
            this.status_id = number;
            this.reload();
        },
        // show modal
        create() {
            this.form.reset();
            this.form.clear();
            this.editMode = false;
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
        },
        // show modal
        edit(id) {
            this.form.clear();
            this.editMode = true;
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
            this.detailProv(id);
        },
        close() {
            this.form.reset();
            this.$root.$emit("bv::hide::modal", "modal");
            this.showModalForm == false;
        },
        // data detail provinsi
        detailProv(id) {
            axios
                .get("/api/data-induk/wilayah-indonesia/provinsi/" + id)
                .then((response) => {
                    this.form.fill(response.data.data);
                    this.prov_id = response.data.data.id;
                    this.errors = [];
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // store data provinsi
        store() {
            this.isLoading = true
            this.form.nama = this.form.nama.toUpperCase();
            this.form
                .post("/api/data-induk/wilayah-indonesia/provinsi")
                .then((response) => {
                    this.isLoading = false
                    this.form.reset();
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil ditambah",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        allowOutsideClick: false,
                    });
                    this.reload();
                })
                .catch((error) => {
                    this.isLoading = false
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // ...
                    }
                });
        },
        // store data provinsi
        update(id) {
            this.isLoading = true
            this.form.nama = this.form.nama.toUpperCase();
            this.form
                .put("/api/data-induk/wilayah-indonesia/provinsi/" + id)
                .then((response) => {
                    this.isLoading = false
                    this.$swal({
                        icon: "success",
                        title: "Data berhasil dirubah",
                        type: "success",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        allowOutsideClick: false,
                    });
                    this.reload();
                })
                .catch((error) => {
                    this.isLoading = false
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // ...
                    }
                });
        },
    },
};
</script>

<style scoped>

</style>
